.navbar{
    
}
.navbar-title{
    font-weight: bold;
    align-self: center;
}
.navbar-nav-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
