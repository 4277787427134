.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-inpu-outlined,
.ant-input:hover,
.ant-input:focus,
.ant-input:active {
  box-shadow: none !important;
  background-color: none !important;
  border-color: none !important;
}
.form-control,
.ant-picker,
.ant-input-outlined,
.ant-select,
.ant-select-selector,
.ant-select-single {
  border: 1.5px solid transparent !important;
  background-color: whitesmoke !important;
  border-radius: 0%;
  height: 2.4rem;
}
.ant-checkbox .ant-checkbox-inner{
  border: 1px solid #34356b !important;
  background-color: whitesmoke !important;
  border-radius: 0%;
}
.ant-checkbox-checked .ant-checkbox-inner{
  border: 1px solid #34356b !important;
  background-color: #34356b !important;
  border-radius: 0%;
}
.ant-radio-button,
.ant-radio-button-wrapper {
  border: 1px solid whitesmoke !important;
  border-radius: 0% !important;
}
.ant-radio-button-checked,
.ant-radio-button-wrapper-checked {
  border-color: #34356b !important;
  background-color: whitesmoke !important;
}
.ant-radio-button-wrapper {
  color: #34356b !important;
}
.ant-radio-button-wrapper-checked::before {
  background-color: #34356b !important;
}

.form-control:hover,
.form-control:focus,
.form-control:active,
.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:active,
.ant-select-selector:active,
.ant-select-selector:focus,
.ant-select:hover,
.ant-select:focus,
.ant-select:active,
.ant-select-single:hover,
.ant-select-single:focus,
.ant-select-single:active,
.ant-picker:hover,
.ant-picker:focus,
.ant-picker:active {
  box-shadow: none !important;
  background-color: whitesmoke !important;
  border-color: #34356b !important;
}

.form-control:disabled,
.ant-input-outlined:disabled,
.ant-select:disabled,
.ant-select-single:disabled{
  box-shadow: none !important;
  background-color: whitesmoke !important;
  border: 1.5px solid transparent !important;
}

.form-check-input,
.form-check-input:focus,
.form-check-input:active {
  cursor: pointer;
  color: white  !important;
  background-color: white !important;
  border:2px solid #d7d7e9;
  box-shadow: none !important;
}


.form-check-input:checked {
  box-shadow: none !important;
  color: white  !important;
  background-color: #34356b !important;
  border-color: #34356b !important;
}



.ant-btn {
  color:white;
  border-color: #34356b;
  background-color: #34356b;
  cursor: pointer;
}

/* Styles for the button on hover ant-btn-primary */
.ant-btn:hover {
  color: black  !important;
  background-color: white !important;
  border-color: #34356b !important;
}

/* Styles for the button icon on hover */

.ant-btn-icon .anticon :hover,
.ant-btn-icon .anticon :focus,
.ant-btn-icon .anticon :active {
  color: black !important;
}

.ant-pagination-item:hover,
.ant-pagination-next:hover,
.ant-pagination-previous:hover {
  background-color: #34356b !important;
  color: white !important;
}
.ant-pagination-item-active {
  border-color: #34356b !important;
}


.ant-pagination-item-ellipsis {
  color: #ab9cff !important; /* Change color of ellipsis to match background */
}
.ant-pagination-item-link-icon {
  color: #34356b !important; /* Change color of pagination icon */
}
.ant-timeline-item-head,
.ant-timeline-item-head-blue{
  color: #34356b !important; 
  border-color: #34356b !important; 
}
.ant-picker-cell-today .ant-picker-cell-inner{
  border-color: #34356b !important; 
}

.ant-menu-item-selected,
.ant-picker-cell-selected .ant-picker-cell-inner{
  background-color: #34356b !important;
  border-color: #34356b !important; 
}

.ant-picker-now-btn{
  color: #34356b !important;
}
/* Loading spinner */
.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #34356b; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Center the spinner */
}
/* Loading spinner small */
.spinnersmall {
  border: 1.5px solid #f3f3f3; /* Light grey */
  border-top: 1.5px solid #34356b; /* Blue */
  border-radius: 50%;
  height: 14px;
  width: 14px;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#accountdetails {
  margin-top: 80px; /* Adjust as needed */
}

.ant-breadcrumb-link{
  text-decoration: none;
}

.clickable-row {
  cursor: pointer;
}
.ant-upload-drag:hover {
  border-color: #34356b !important;
}

.bg-purple{
  background-color: #34356b !important;
}
.bg-transparent{
  background-color: transparent !important;
}

/* styles.css */
@keyframes pulse {
  0% {
    background-color: #34356b; /* Primary color */
    
  }
  50% {
    background-color: #ab9cff; /* Light blue */
  }
  100% {
    background-color: #34356b; /* Primary color */
  }
}

.pulse-animation {
  animation: pulse 2s infinite;
}
